// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../static/SplashBkg.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".add-to-discord[data-v-09b73684]{background:#5865f2;box-shadow:1.95px 1.95px 2.6px rgba(0,0,0,.15);text-decoration:none}#splash-card[data-v-09b73684]{opacity:.9}#splash-image[data-v-09b73684]{box-shadow:0 5px 15px rgba(0,0,0,.35);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;transform:translatez(0);-webkit-transform:translatez(0);-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;background-position:bottom}#help-tiles[data-v-09b73684]{border-radius:25px}#nav-tile[data-v-09b73684],#nav-tile[data-v-09b73684] :hover{transition:all .1s ease-in-out}#nav-tile[data-v-09b73684] :hover{transform:scale(1.015)}#nav-child[data-v-09b73684] :hover{transition:none;transform:none}#hero-columns[data-v-09b73684]{width:100%}@media (max-width:1024px){#hero-columns[data-v-09b73684]{padding-right:0}#splash-card[data-v-09b73684]{margin:1.5rem!important}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
